@use ".././node_modules/@siemens/ngx-datatable/index.css";
@use ".././node_modules/@siemens/ngx-datatable/themes/bootstrap.scss";
@use ".././node_modules/@siemens/ngx-datatable/assets/icons.css";
@use "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@use "./styles/theme";
@use "/src/styles/variables" as*;

.datatable-body-cell {
  border: 1px solid #46484a;
}

.express-item {
  margin-left: 10px;
  background-color: green;
  padding: 5px;
  border-radius: 9px;
  color: white;
}

.filter-cell {
  background-color: #3d3d3e;
}

.requared {
  color: red;
}

.dark-theme {
  .spiner-text {
    color: #ffffff;
  }

  .selected-row {
    background-color: #67319d !important;
  }
}

.light-theme {
  .spiner-text {
    color: rgb(63, 118, 166);
  }

  .selected-row {
    background-color: #b6bb40b5 !important;
  }
}

.spiner-text {
  font: DIN;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1%;
  position: relative;
  z-index: 100000;
}

.btn-reset {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  height: 48px;
  min-width: 84px;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  font-family: DIN;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
  letter-spacing: 0.24px;
}

.btn-reset:active {
  border-color: white;
  color: white;
}

.btn-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  font-family: DIN;
  min-width: 84px;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  line-height: 150%;
  letter-spacing: 0.24px;
  background-color: #845ae0;
  border: none;
}

.btn-submit:active {
  border-color: white;
  background-color: white;
  color: #151516;
}

.light-theme .btn-submit:active {
  border-color: black;
  background-color: white;
  color: #151516;
}

@font-face {
  font-family: DIN;
  src: url(assets/DIN.ttf);
}

.datatable-header-cell-template-wrap {
  display: flex;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.invisible {
  visibility: hidden;
}

.multiline {
  white-space: pre-wrap;
}

.clickable {
  cursor: pointer;
}

.unclickable {
  cursor: unset !important;
}

.no-margin {
  margin: 0;
}

.margin-right-3 {
  margin-right: 1rem !important;
}

.margin-left-3 {
  margin-left: 1rem !important;
}

.margin-right-2 {
  margin-right: 20px !important;
}

.margin-left-2 {
  margin-left: 0.5rem !important;
}

.margin-left-3rem {
  margin-left: 3rem !important;
}

.margin-top-0-75 {
  margin-top: 0.75rem !important;
}

.margin-top-3 {
  margin-top: 1rem !important;
}

.margin-top-2 {
  margin-top: 20px !important;
}

.margin-top-2rem {
  margin-top: 2rem !important;
}

.margin-top-4 {
  margin-top: 1.5rem !important;
}

.margin-bottom-3 {
  margin-bottom: 1rem !important;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}

.margin-bottom-3rem {
  margin-bottom: 3rem !important;
}

.no-padding {
  padding: 0;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.padding-3 {
  padding: 1rem !important;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem !important;
}

.margin-top-3-point-1rem {
  margin-top: 3.1rem !important;
}

.margin-top-5rem {
  margin-top: 5rem !important;
}

.flex-container {
  display: flex;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-right-align {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-align-center {
  align-items: center;
}

.without-color {
  background-color: inherit !important;
}

.with-shadow {
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
}

.reduced-font {
  font-size: 90%;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.s3 {
  width: 30%;
}

.s2 {
  width: 20%;
}

.s1 {
  width: 10%;
}

.max-width-300px {
  max-width: 300px;
}

.max-width-400px {
  max-width: 400px;
}

.redRow {
  background-color: #e8194e !important;
  color: black !important;

  a {
    color: black !important;
  }
}

.yellowRow {
  background-color: yellow !important;
  color: black !important;

  a {
    color: black !important;
  }
}

.lemontreeRow {
  background-color: #00c896 !important;
  color: black !important;

  a {
    color: black !important;
  }
}

.pomaranczowyRow {
  background-color: #ffae15 !important;
  color: black !important;

  a {
    color: black !important;
  }
}

.dark-theme {
  .table-row-first {
    background-color: #252525 !important;
  }

  .table-row-second {
    background-color: #2D3332 !important;
  }

  .pdf-send-error {
    border: 1px solid $error-dark-border-color !important;
    background-color: $error-dark-background-color !important;
  }
}

.light-theme {
  .table-row-first {
    background-color: #fff !important;
  }

  .table-row-second {
    background-color: #DEDEDE !important;
  }

  .pdf-send-error {
    border: 1px solid $error-light-border-color !important;
    background-color: $error-light-background-color !important;
  }
}

.new-container {
  width: 100%;
  padding: 0 20px;

  &.mob-none-padding {
    padding: 0;

    @media (min-width: 1024px) {
      padding: 0 20px;
    }
  }
}

.new-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: -8px;

  &--gap {
    .new-col {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

.new-col {
  width: 100%;
  padding: 8px;
  min-width: 305px;

  &--textarea {
    flex: none;
    width: 100% !important;
    max-width: 889px !important;
  }

  &--center {
    position: relative;
    top: -12px;
  }

  &--checkbox {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;

    @media (min-width: 768px) {
      height: 98px;
    }
  }

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 1024px) {
    width: 33.33%;
  }
}

.tab-content {
  background-color: #2d2d2f;

  &__futer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 32px;
    padding-right: 20px;

    @media (min-width: 1024px) {
      padding: 32px 50px 20px;
    }
  }

  &__head {
    padding: 20px;
    border-bottom: 1px solid #3d3d3e;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media (min-width: 1024px) {
      padding: 32px 50px 20px;
    }
  }

  &__head-descr {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__head-back {
    display: flex;
    align-items: center;
    gap: 3px;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;

    path {
      stroke: #f8f8f8;
    }
  }

  &__head-title {
    font-size: 18px;
    line-height: 150%;
    font-weight: 700;
    color: #ffffff;
    margin: 0;

    @media (min-width: 1024px) {
      font-size: 24px;
    }
  }

  &__body {
    padding: 20px;
    border-radius: 0 0 10px 10px;

    @media (min-width: 1024px) {
      padding: 20px 30px 30px;
    }

    &--pay {
      min-height: calc(100vh - 290px);
    }
  }

  .default-input {
    width: 100% !important;
  }

  .accordion-item {
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: #202021;
    border: none;

    @media (min-width: 1024px) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-line {
    margin: 0 20px;
    width: 100%;
    height: 2px;
    background-color: #fff;

    @media (min-width: 1024px) {
      margin: 0 50px;
    }
  }

  .accordion-button {
    appearance: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    line-height: 150%;
    cursor: pointer;
    min-height: 60px;
    display: flex;
    align-items: center;

    @media (min-width: 1024px) {
      font-size: 20px;
      height: 70px;
    }

    span {
      @media (min-width: 1024px) {
        flex: none;
      }
    }

    &::after {
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L17 1' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  .accordion-body {
    padding-top: 0;
  }

  .accordion-border {
    border-bottom: 1px solid #3d3d3e;
    margin: 20px 0;
  }

  .accordion-interior {
    border-top: 1px solid #3d3d3e;
    padding-top: 20px;

    .accordion-item {
      margin-bottom: 20px;
      background-color: #2d2d2f;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .accordion-button {
      min-height: 50px;
    }
  }

  .radio-wrapper {
    max-width: 739px;
  }
}

.buttons-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &>* {
    width: 100%;
  }
}

.data-table {
  &__wrap {
    border-top: 1px solid #3d3d3e;
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &__col {
    width: 233px;
    margin-right: 16px;
    color: #ffffff;
    line-height: 150%;
    font-size: 15px;

    @media (min-width: 1024px) {
      font-size: 16px;
    }

    &--full {
      width: 100%;
    }

    &--half {
      width: 50%;

      .data-table__col {
        min-width: 290px;

        @media (min-width: 1024px) {
          width: 100%;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__descr {
    width: 403px;
    flex: none;
  }

  &__cashout {
    width: 718px;
    flex: none;
  }

  &__acc {
    width: 100%;

    .accordion-interior {
      border-top: none !important;
    }
  }
}

.bold-text {
  font-weight: 700;
}

.files-wrapper {
  max-width: 739px;

  &__item {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #3d3d3e;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__item-descr {
    display: flex;
    flex-direction: column;
    width: 363px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 30px;

    a {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__item-label {
    color: #fff;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @media (min-width: 1024px) {
      font-size: 16px;
    }

    span {
      color: #8d2f2f;
      margin-left: 8px;
    }
  }
}

.footer-table {
  padding: 28px 28px 28px 70px;
  border-radius: 6px;
  background-color: #353535;

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__col {
    color: #fff;
    font-size: 16px;
    text-align: right;
    line-height: 150%;

    &:last-child {
      margin-left: 32px;
    }

    input {
      width: 95px;
      border: none;
      background: transparent !important;
      color: #fff !important;
      font-size: 16px;
      text-align: right;
      line-height: 150%;
    }
  }
}

.charts-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.pay-block {
  &--packege {
    border-bottom: 1px dashed #fff;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    margin-bottom: 16px;
  }

  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
  }

  &__head {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__content {
    margin-bottom: 32px;
    margin-left: 20px;
  }

  &__col {
    width: 100%;
    height: 58px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 1024px) {
      width: 25%;
      margin-bottom: 0;
    }

    &--number {
      width: 50px;
      flex: none;
      padding: 8px 12px;
      color: #ffffff;
      margin-bottom: 0;
    }

    &--btn {
      @media (min-width: 1024px) {
        width: 48px;
        flex: none;
      }
    }

    .btn-submit {
      min-width: auto;
      height: 48px;
      width: 100%;
      padding: 5px;

      @media (min-width: 1024px) {
        width: 48px;
      }
    }
  }

  &__label {
    margin-bottom: 8px;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &__textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &>* {
      height: 100%;
    }
  }

  &__descr {
    flex: none;
    border-radius: 6px;
    background-color: #353535;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (min-width: 1024px) {
      width: 358px;
    }
  }

  &__descr-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__descr-col {
    font-size: 16px;
    color: #fff;
    line-height: 150%;
    text-align: right;

    &:last-child {
      font-weight: 700;
      min-width: 95px;
      margin-left: 32px;
    }
  }
}

.bambora-card {
  background-color: transparent;
  border: none;

  .bambora-card-body {
    padding: 0;
  }

  .card-row {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }

  .card-col {
    width: 50%;
  }

  .form-label {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;

    span {
      font-size: 16px;
      color: #8d2f2f;
      position: relative;
      right: -4px;
    }
  }

  input {
    height: 48px;
    color: #ffffff;
    font-family: DIN;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    border-radius: 6px;
    padding: 4px 16px;
    background-color: #262626;
    border: 1px solid #313335 !important;
  }
}

.margin-right-16px {
  margin-right: 16px;
}

.alt-form {
  &__row {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    color: #ffffff;
    margin-right: 16px;
    margin-top: 3px;
  }

  &__field {
    margin-top: 16px;
  }

  &__label {
    font-size: 16px;
    color: #fff;
    line-height: 150%;
    margin-bottom: 10px;
  }
}

.refund-wrap-bg {
  background-color: #353535;
  padding: 30px;
  border-radius: 6px;

  &+.refund-wrap-bg {
    margin-top: 20px;
  }
}

.refund-wrap {
  &__row {
    font-size: 16px;
    color: #ffffff;
    line-height: 150%;
  }

  &__descr {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__block {
    margin-top: 16px;
  }
}

.report-wrap {
  &__form {
    max-width: 977px;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 32px;
  }
}

.breadcrumbs {
  padding: 32px 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;

  &__item {
    margin-right: 5px;
    padding-right: 11px;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 10px;
      right: 0;
      top: 55%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9.5L5 5.5L1 1.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__link {
    color: #fff;
    font-size: 14px;
    line-height: 150%;
    text-decoration: none;
    transition: all 0.3s ease;
  }
}

.report-filter {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 16px;

  &__wrap {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #313335;
  }

  &__btn {
    color: #ffffff;
    background-color: #2d2d2f;
    border: none;
    border-right: 1px solid #313335 !important;
    height: 48px;
    cursor: pointer;
    font-size: 16px;
    line-height: 150%;
    padding: 12px 16px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;

    &:last-child {
      border-right: none !important;
    }

    &:hover,
    &.active {
      background-color: #845ae0;
      border-right: 1px solid #845ae0;
    }
  }
}

.order-system-wrap {
  .container {
    padding: 0;
  }
}

.modal-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 30px;

  &--cert {
    .btn-submit {
      min-width: 207px;

      @media (min-width: 768px) {
        min-width: auto;
      }
    }
  }
}

.margin-bottom-1 {
  margin-bottom: 10px;
}

.margin-bottom-2 {
  margin-bottom: 20px;
}

.admin-filters {
  margin-bottom: 30px;

  &__form {
    margin-bottom: 20px;
    max-width: 806px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  &__col {
    width: 100%;
    padding: 8px;

    @media (min-width: 768px) {
      width: 50%;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &--padding {
      padding-bottom: 250px;
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  gap: 10px;

  &+& {
    margin-top: 16px;
  }
}

.btn-closed {
  width: 24px;
  height: 24px;
  opacity: 1;
  border: none;
  background-color: transparent;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.0001 14.1216L17.3031 19.4246C17.5845 19.706 17.9661 19.8641 18.3641 19.8641C18.762 19.8641 19.1437 19.706 19.4251 19.4246C19.7065 19.1432 19.8646 18.7616 19.8646 18.3636C19.8646 17.9657 19.7065 17.584 19.4251 17.3026L14.1201 11.9996L19.4241 6.69662C19.5634 6.55729 19.6738 6.39189 19.7492 6.20987C19.8245 6.02785 19.8633 5.83277 19.8632 5.63577C19.8632 5.43877 19.8243 5.24371 19.7489 5.06172C19.6735 4.87974 19.5629 4.71439 19.4236 4.57512C19.2843 4.43586 19.1189 4.3254 18.9368 4.25005C18.7548 4.1747 18.5597 4.13595 18.3627 4.13599C18.1657 4.13604 17.9707 4.17489 17.7887 4.25032C17.6067 4.32575 17.4414 4.43629 17.3021 4.57562L12.0001 9.87862L6.69709 4.57562C6.55879 4.43229 6.39333 4.31794 6.21036 4.23924C6.02739 4.16055 5.83058 4.11907 5.63141 4.11725C5.43224 4.11543 5.23471 4.15329 5.05033 4.22862C4.86595 4.30395 4.69842 4.41526 4.55752 4.55603C4.41661 4.6968 4.30515 4.86422 4.22964 5.04853C4.15414 5.23284 4.11609 5.43034 4.11773 5.62951C4.11936 5.82868 4.16065 6.02553 4.23917 6.20857C4.3177 6.39161 4.43189 6.55718 4.57509 6.69562L9.88009 11.9996L4.57609 17.3036C4.43289 17.4421 4.3187 17.6076 4.24017 17.7907C4.16165 17.9737 4.12036 18.1706 4.11873 18.3697C4.11709 18.5689 4.15514 18.7664 4.23064 18.9507C4.30615 19.135 4.41761 19.3024 4.55852 19.4432C4.69942 19.584 4.86695 19.6953 5.05133 19.7706C5.23571 19.846 5.43324 19.8838 5.63241 19.882C5.83158 19.8802 6.02839 19.8387 6.21136 19.76C6.39433 19.6813 6.55979 19.5669 6.69809 19.4236L12.0001 14.1216Z' fill='%23F8F8F8'/%3E%3C/svg%3E%0A") !important;
}

.bs-datepicker .bs-media-container>* {
  width: 100%;
}

.modal-data {
  &__title {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
  }

  &__row {
    display: flex;
    align-items: flex-end;
    gap: 15px;
  }

  &__col {
    width: 100%;

    &--btn {
      width: 48px;
      height: 48px;
      flex: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--center {
      display: flex;
      align-items: center;
    }
  }

  &__btn-close {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    background-color: #845AE0;
    border: none;
  }
}

.bs-datepicker-body table td span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.charts-wrap {
  padding-bottom: 20px;
  overflow: hidden;
  overflow-x: auto;
}

.my-goal {
  padding-top: 30px;
}